export default [
  {
    path: "/",
    name: "Register",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/Register.vue"),
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      requiresAuth: false,
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    path: "/",
    name: "RegisterInterests",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(
        /* webpackChunkName: "registerinterests" */ "@/views/RegisterInterests.vue"
      ),
  },
  {
    path: "/catalog",
    name: "Catalog",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "catalog" */ "@/views/Catalog.vue"),
  },
  {
    path: "/game/:game",
    name: "Game",
    meta: {
      requiresAuth: true,
    },
    component: () => import(/* webpackChunkName: "game" */ "@/views/Game.vue"),
  },
  {
    path: "/leaderboard/:game",
    name: "Leaderboard",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "leaderboard" */ "@/views/Leaderboard.vue"),
  },
  {
    path: "/rewards",
    name: "Rewards",
    meta: {
      requiresAuth: true,
    },
    component: () =>
      import(/* webpackChunkName: "rewards" */ "@/views/Rewards.vue"),
  },
];
